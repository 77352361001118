export default {
  AD: { name: 'Andorra', latitude: 42.546245, longitude: 1.601554 },
  AE: {
    name: 'United Arab Emirates',
    latitude: 23.424076,
    longitude: 53.847818,
  },
  AF: { name: 'Afghanistan', latitude: 33.93911, longitude: 67.709953 },
  AG: {
    name: 'Antigua and Barbuda',
    latitude: 17.060816,
    longitude: -61.796428,
  },
  AI: { name: 'Anguilla', latitude: 18.220554, longitude: -63.068615 },
  AL: { name: 'Albania', latitude: 41.153332, longitude: 20.168331 },
  AM: { name: 'Armenia', latitude: 40.069099, longitude: 45.038189 },
  AN: {
    name: 'Netherlands Antilles',
    latitude: 12.226079,
    longitude: -69.060087,
  },
  AO: { name: 'Angola', latitude: -11.202692, longitude: 17.873887 },
  AQ: { name: 'Antarctica', latitude: -75.250973, longitude: -0.071389 },
  AR: { name: 'Argentina', latitude: -38.416097, longitude: -63.616672 },
  AS: { name: 'American Samoa', latitude: -14.270972, longitude: -170.132217 },
  AT: { name: 'Austria', latitude: 47.516231, longitude: 14.550072 },
  AU: { name: 'Australia', latitude: -25.274398, longitude: 133.775136 },
  AW: { name: 'Aruba', latitude: 12.52111, longitude: -69.968338 },
  AZ: { name: 'Azerbaijan', latitude: 40.143105, longitude: 47.576927 },
  BA: {
    name: 'Bosnia and Herzegovina',
    latitude: 43.915886,
    longitude: 17.679076,
  },
  BB: { name: 'Barbados', latitude: 13.193887, longitude: -59.543198 },
  BD: { name: 'Bangladesh', latitude: 23.684994, longitude: 90.356331 },
  BE: { name: 'Belgium', latitude: 50.503887, longitude: 4.469936 },
  BF: { name: 'Burkina Faso', latitude: 12.238333, longitude: -1.561593 },
  BG: { name: 'Bulgaria', latitude: 42.733883, longitude: 25.48583 },
  BH: { name: 'Bahrain', latitude: 25.930414, longitude: 50.637772 },
  BI: { name: 'Burundi', latitude: -3.373056, longitude: 29.918886 },
  BJ: { name: 'Benin', latitude: 9.30769, longitude: 2.315834 },
  BM: { name: 'Bermuda', latitude: 32.321384, longitude: -64.75737 },
  BN: { name: 'Brunei', latitude: 4.535277, longitude: 114.727669 },
  BO: { name: 'Bolivia', latitude: -16.290154, longitude: -63.588653 },
  BR: { name: 'Brazil', latitude: -14.235004, longitude: -51.92528 },
  BS: { name: 'Bahamas', latitude: 25.03428, longitude: -77.39628 },
  BT: { name: 'Bhutan', latitude: 27.514162, longitude: 90.433601 },
  BV: { name: 'Bouvet Island', latitude: -54.423199, longitude: 3.413194 },
  BW: { name: 'Botswana', latitude: -22.328474, longitude: 24.684866 },
  BY: { name: 'Belarus', latitude: 53.709807, longitude: 27.953389 },
  BZ: { name: 'Belize', latitude: 17.189877, longitude: -88.49765 },
  CA: { name: 'Canada', latitude: 56.130366, longitude: -106.346771 },
  CC: {
    name: 'Cocos [Keeling] Islands',
    latitude: -12.164165,
    longitude: 96.870956,
  },
  CD: { name: 'Congo [DRC]', latitude: -4.038333, longitude: 21.758664 },
  CF: {
    name: 'Central African Republic',
    latitude: 6.611111,
    longitude: 20.939444,
  },
  CG: { name: 'Congo [Republic]', latitude: -0.228021, longitude: 15.827659 },
  CH: { name: 'Switzerland', latitude: 46.818188, longitude: 8.227512 },
  CI: { name: "C\u00f4te d'Ivoire", latitude: 7.539989, longitude: -5.54708 },
  CK: { name: 'Cook Islands', latitude: -21.236736, longitude: -159.777671 },
  CL: { name: 'Chile', latitude: -35.675147, longitude: -71.542969 },
  CM: { name: 'Cameroon', latitude: 7.369722, longitude: 12.354722 },
  CN: { name: 'China', latitude: 35.86166, longitude: 104.195397 },
  CO: { name: 'Colombia', latitude: 4.570868, longitude: -74.297333 },
  CR: { name: 'Costa Rica', latitude: 9.748917, longitude: -83.753428 },
  CU: { name: 'Cuba', latitude: 21.521757, longitude: -77.781167 },
  CV: { name: 'Cape Verde', latitude: 16.002082, longitude: -24.013197 },
  CX: { name: 'Christmas Island', latitude: -10.447525, longitude: 105.690449 },
  CY: { name: 'Cyprus', latitude: 35.126413, longitude: 33.429859 },
  CZ: { name: 'Czech Republic', latitude: 49.817492, longitude: 15.472962 },
  DE: { name: 'Germany', latitude: 51.165691, longitude: 10.451526 },
  DJ: { name: 'Djibouti', latitude: 11.825138, longitude: 42.590275 },
  DK: { name: 'Denmark', latitude: 56.26392, longitude: 9.501785 },
  DM: { name: 'Dominica', latitude: 15.414999, longitude: -61.370976 },
  DO: {
    name: 'Dominican Republic',
    latitude: 18.735693,
    longitude: -70.162651,
  },
  DZ: { name: 'Algeria', latitude: 28.033886, longitude: 1.659626 },
  EC: { name: 'Ecuador', latitude: -1.831239, longitude: -78.183406 },
  EE: { name: 'Estonia', latitude: 58.595272, longitude: 25.013607 },
  EG: { name: 'Egypt', latitude: 26.820553, longitude: 30.802498 },
  EH: { name: 'Western Sahara', latitude: 24.215527, longitude: -12.885834 },
  ER: { name: 'Eritrea', latitude: 15.179384, longitude: 39.782334 },
  ES: { name: 'Spain', latitude: 40.463667, longitude: -3.74922 },
  ET: { name: 'Ethiopia', latitude: 9.145, longitude: 40.489673 },
  FI: { name: 'Finland', latitude: 61.92411, longitude: 25.748151 },
  FJ: { name: 'Fiji', latitude: -16.578193, longitude: 179.414413 },
  FK: {
    name: 'Falkland Islands [Islas Malvinas]',
    latitude: -51.796253,
    longitude: -59.523613,
  },
  FM: { name: 'Micronesia', latitude: 7.425554, longitude: 150.550812 },
  FO: { name: 'Faroe Islands', latitude: 61.892635, longitude: -6.911806 },
  FR: { name: 'France', latitude: 46.227638, longitude: 2.213749 },
  GA: { name: 'Gabon', latitude: -0.803689, longitude: 11.609444 },
  GB: { name: 'United Kingdom', latitude: 55.378051, longitude: -3.435973 },
  GD: { name: 'Grenada', latitude: 12.262776, longitude: -61.604171 },
  GE: { name: 'Georgia', latitude: 42.315407, longitude: 43.356892 },
  GF: { name: 'French Guiana', latitude: 3.933889, longitude: -53.125782 },
  GG: { name: 'Guernsey', latitude: 49.465691, longitude: -2.585278 },
  GH: { name: 'Ghana', latitude: 7.946527, longitude: -1.023194 },
  GI: { name: 'Gibraltar', latitude: 36.137741, longitude: -5.345374 },
  GL: { name: 'Greenland', latitude: 71.706936, longitude: -42.604303 },
  GM: { name: 'Gambia', latitude: 13.443182, longitude: -15.310139 },
  GN: { name: 'Guinea', latitude: 9.945587, longitude: -9.696645 },
  GP: { name: 'Guadeloupe', latitude: 16.995971, longitude: -62.067641 },
  GQ: { name: 'Equatorial Guinea', latitude: 1.650801, longitude: 10.267895 },
  GR: { name: 'Greece', latitude: 39.074208, longitude: 21.824312 },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    latitude: -54.429579,
    longitude: -36.587909,
  },
  GT: { name: 'Guatemala', latitude: 15.783471, longitude: -90.230759 },
  GU: { name: 'Guam', latitude: 13.444304, longitude: 144.793731 },
  GW: { name: 'Guinea-Bissau', latitude: 11.803749, longitude: -15.180413 },
  GY: { name: 'Guyana', latitude: 4.860416, longitude: -58.93018 },
  GZ: { name: 'Gaza Strip', latitude: 31.354676, longitude: 34.308825 },
  HK: { name: 'Hong Kong', latitude: 22.396428, longitude: 114.109497 },
  HM: {
    name: 'Heard Island and McDonald Islands',
    latitude: -53.08181,
    longitude: 73.504158,
  },
  HN: { name: 'Honduras', latitude: 15.199999, longitude: -86.241905 },
  HR: { name: 'Croatia', latitude: 45.1, longitude: 15.2 },
  HT: { name: 'Haiti', latitude: 18.971187, longitude: -72.285215 },
  HU: { name: 'Hungary', latitude: 47.162494, longitude: 19.503304 },
  ID: { name: 'Indonesia', latitude: -0.789275, longitude: 113.921327 },
  IE: { name: 'Ireland', latitude: 53.41291, longitude: -8.24389 },
  IL: { name: 'Israel', latitude: 31.046051, longitude: 34.851612 },
  IM: { name: 'Isle of Man', latitude: 54.236107, longitude: -4.548056 },
  IN: { name: 'India', latitude: 20.593684, longitude: 78.96288 },
  IO: {
    name: 'British Indian Ocean Territory',
    latitude: -6.343194,
    longitude: 71.876519,
  },
  IQ: { name: 'Iraq', latitude: 33.223191, longitude: 43.679291 },
  IR: { name: 'Iran', latitude: 32.427908, longitude: 53.688046 },
  IS: { name: 'Iceland', latitude: 64.963051, longitude: -19.020835 },
  IT: { name: 'Italy', latitude: 41.87194, longitude: 12.56738 },
  JE: { name: 'Jersey', latitude: 49.214439, longitude: -2.13125 },
  JM: { name: 'Jamaica', latitude: 18.109581, longitude: -77.297508 },
  JO: { name: 'Jordan', latitude: 30.585164, longitude: 36.238414 },
  JP: { name: 'Japan', latitude: 36.204824, longitude: 138.252924 },
  KE: { name: 'Kenya', latitude: -0.023559, longitude: 37.906193 },
  KG: { name: 'Kyrgyzstan', latitude: 41.20438, longitude: 74.766098 },
  KH: { name: 'Cambodia', latitude: 12.565679, longitude: 104.990963 },
  KI: { name: 'Kiribati', latitude: -3.370417, longitude: -168.734039 },
  KM: { name: 'Comoros', latitude: -11.875001, longitude: 43.872219 },
  KN: {
    name: 'Saint Kitts and Nevis',
    latitude: 17.357822,
    longitude: -62.782998,
  },
  KP: { name: 'North Korea', latitude: 40.339852, longitude: 127.510093 },
  KR: { name: 'South Korea', latitude: 35.907757, longitude: 127.766922 },
  KW: { name: 'Kuwait', latitude: 29.31166, longitude: 47.481766 },
  KY: { name: 'Cayman Islands', latitude: 19.513469, longitude: -80.566956 },
  KZ: { name: 'Kazakhstan', latitude: 48.019573, longitude: 66.923684 },
  LA: { name: 'Laos', latitude: 19.85627, longitude: 102.495496 },
  LB: { name: 'Lebanon', latitude: 33.854721, longitude: 35.862285 },
  LC: { name: 'Saint Lucia', latitude: 13.909444, longitude: -60.978893 },
  LI: { name: 'Liechtenstein', latitude: 47.166, longitude: 9.555373 },
  LK: { name: 'Sri Lanka', latitude: 7.873054, longitude: 80.771797 },
  LR: { name: 'Liberia', latitude: 6.428055, longitude: -9.429499 },
  LS: { name: 'Lesotho', latitude: -29.609988, longitude: 28.233608 },
  LT: { name: 'Lithuania', latitude: 55.169438, longitude: 23.881275 },
  LU: { name: 'Luxembourg', latitude: 49.815273, longitude: 6.129583 },
  LV: { name: 'Latvia', latitude: 56.879635, longitude: 24.603189 },
  LY: { name: 'Libya', latitude: 26.3351, longitude: 17.228331 },
  MA: { name: 'Morocco', latitude: 31.791702, longitude: -7.09262 },
  MC: { name: 'Monaco', latitude: 43.750298, longitude: 7.412841 },
  MD: { name: 'Moldova', latitude: 47.411631, longitude: 28.369885 },
  ME: { name: 'Montenegro', latitude: 42.708678, longitude: 19.37439 },
  MG: { name: 'Madagascar', latitude: -18.766947, longitude: 46.869107 },
  MH: { name: 'Marshall Islands', latitude: 7.131474, longitude: 171.184478 },
  MK: { name: 'Macedonia [FYROM]', latitude: 41.608635, longitude: 21.745275 },
  ML: { name: 'Mali', latitude: 17.570692, longitude: -3.996166 },
  MM: { name: 'Myanmar [Burma]', latitude: 21.913965, longitude: 95.956223 },
  MN: { name: 'Mongolia', latitude: 46.862496, longitude: 103.846656 },
  MO: { name: 'Macau', latitude: 22.198745, longitude: 113.543873 },
  MP: {
    name: 'Northern Mariana Islands',
    latitude: 17.33083,
    longitude: 145.38469,
  },
  MQ: { name: 'Martinique', latitude: 14.641528, longitude: -61.024174 },
  MR: { name: 'Mauritania', latitude: 21.00789, longitude: -10.940835 },
  MS: { name: 'Montserrat', latitude: 16.742498, longitude: -62.187366 },
  MT: { name: 'Malta', latitude: 35.937496, longitude: 14.375416 },
  MU: { name: 'Mauritius', latitude: -20.348404, longitude: 57.552152 },
  MV: { name: 'Maldives', latitude: 3.202778, longitude: 73.22068 },
  MW: { name: 'Malawi', latitude: -13.254308, longitude: 34.301525 },
  MX: { name: 'Mexico', latitude: 23.634501, longitude: -102.552784 },
  MY: { name: 'Malaysia', latitude: 4.210484, longitude: 101.975766 },
  MZ: { name: 'Mozambique', latitude: -18.665695, longitude: 35.529562 },
  NA: { name: 'Namibia', latitude: -22.95764, longitude: 18.49041 },
  NC: { name: 'New Caledonia', latitude: -20.904305, longitude: 165.618042 },
  NE: { name: 'Niger', latitude: 17.607789, longitude: 8.081666 },
  NF: { name: 'Norfolk Island', latitude: -29.040835, longitude: 167.954712 },
  NG: { name: 'Nigeria', latitude: 9.081999, longitude: 8.675277 },
  NI: { name: 'Nicaragua', latitude: 12.865416, longitude: -85.207229 },
  NL: { name: 'Netherlands', latitude: 52.132633, longitude: 5.291266 },
  NO: { name: 'Norway', latitude: 60.472024, longitude: 8.468946 },
  NP: { name: 'Nepal', latitude: 28.394857, longitude: 84.124008 },
  NR: { name: 'Nauru', latitude: -0.522778, longitude: 166.931503 },
  NU: { name: 'Niue', latitude: -19.054445, longitude: -169.867233 },
  NZ: { name: 'New Zealand', latitude: -40.900557, longitude: 174.885971 },
  OM: { name: 'Oman', latitude: 21.512583, longitude: 55.923255 },
  PA: { name: 'Panama', latitude: 8.537981, longitude: -80.782127 },
  PE: { name: 'Peru', latitude: -9.189967, longitude: -75.015152 },
  PF: {
    name: 'French Polynesia',
    latitude: -17.679742,
    longitude: -149.406843,
  },
  PG: { name: 'Papua New Guinea', latitude: -6.314993, longitude: 143.95555 },
  PH: { name: 'Philippines', latitude: 12.879721, longitude: 121.774017 },
  PK: { name: 'Pakistan', latitude: 30.375321, longitude: 69.345116 },
  PL: { name: 'Poland', latitude: 51.919438, longitude: 19.145136 },
  PM: {
    name: 'Saint Pierre and Miquelon',
    latitude: 46.941936,
    longitude: -56.27111,
  },
  PN: {
    name: 'Pitcairn Islands',
    latitude: -24.703615,
    longitude: -127.439308,
  },
  PR: { name: 'Puerto Rico', latitude: 18.220833, longitude: -66.590149 },
  PS: {
    name: 'Palestinian Territories',
    latitude: 31.952162,
    longitude: 35.233154,
  },
  PT: { name: 'Portugal', latitude: 39.399872, longitude: -8.224454 },
  PW: { name: 'Palau', latitude: 7.51498, longitude: 134.58252 },
  PY: { name: 'Paraguay', latitude: -23.442503, longitude: -58.443832 },
  QA: { name: 'Qatar', latitude: 25.354826, longitude: 51.183884 },
  RE: { name: 'R\u00e9union', latitude: -21.115141, longitude: 55.536384 },
  RO: { name: 'Romania', latitude: 45.943161, longitude: 24.96676 },
  RS: { name: 'Serbia', latitude: 44.016521, longitude: 21.005859 },
  RU: { name: 'Russia', latitude: 61.52401, longitude: 105.318756 },
  RW: { name: 'Rwanda', latitude: -1.940278, longitude: 29.873888 },
  SA: { name: 'Saudi Arabia', latitude: 23.885942, longitude: 45.079162 },
  SB: { name: 'Solomon Islands', latitude: -9.64571, longitude: 160.156194 },
  SC: { name: 'Seychelles', latitude: -4.679574, longitude: 55.491977 },
  SD: { name: 'Sudan', latitude: 12.862807, longitude: 30.217636 },
  SE: { name: 'Sweden', latitude: 60.128161, longitude: 18.643501 },
  SG: { name: 'Singapore', latitude: 1.352083, longitude: 103.819836 },
  SH: { name: 'Saint Helena', latitude: -24.143474, longitude: -10.030696 },
  SI: { name: 'Slovenia', latitude: 46.151241, longitude: 14.995463 },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    latitude: 77.553604,
    longitude: 23.670272,
  },
  SK: { name: 'Slovakia', latitude: 48.669026, longitude: 19.699024 },
  SL: { name: 'Sierra Leone', latitude: 8.460555, longitude: -11.779889 },
  SM: { name: 'San Marino', latitude: 43.94236, longitude: 12.457777 },
  SN: { name: 'Senegal', latitude: 14.497401, longitude: -14.452362 },
  SO: { name: 'Somalia', latitude: 5.152149, longitude: 46.199616 },
  SR: { name: 'Suriname', latitude: 3.919305, longitude: -56.027783 },
  ST: {
    name: 'S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    latitude: 0.18636,
    longitude: 6.613081,
  },
  SV: { name: 'El Salvador', latitude: 13.794185, longitude: -88.89653 },
  SY: { name: 'Syria', latitude: 34.802075, longitude: 38.996815 },
  SZ: { name: 'Swaziland', latitude: -26.522503, longitude: 31.465866 },
  TC: {
    name: 'Turks and Caicos Islands',
    latitude: 21.694025,
    longitude: -71.797928,
  },
  TD: { name: 'Chad', latitude: 15.454166, longitude: 18.732207 },
  TF: {
    name: 'French Southern Territories',
    latitude: -49.280366,
    longitude: 69.348557,
  },
  TG: { name: 'Togo', latitude: 8.619543, longitude: 0.824782 },
  TH: { name: 'Thailand', latitude: 15.870032, longitude: 100.992541 },
  TJ: { name: 'Tajikistan', latitude: 38.861034, longitude: 71.276093 },
  TK: { name: 'Tokelau', latitude: -8.967363, longitude: -171.855881 },
  TL: { name: 'Timor-Leste', latitude: -8.874217, longitude: 125.727539 },
  TM: { name: 'Turkmenistan', latitude: 38.969719, longitude: 59.556278 },
  TN: { name: 'Tunisia', latitude: 33.886917, longitude: 9.537499 },
  TO: { name: 'Tonga', latitude: -21.178986, longitude: -175.198242 },
  TR: { name: 'Turkey', latitude: 38.963745, longitude: 35.243322 },
  TT: {
    name: 'Trinidad and Tobago',
    latitude: 10.691803,
    longitude: -61.222503,
  },
  TV: { name: 'Tuvalu', latitude: -7.109535, longitude: 177.64933 },
  TW: { name: 'Taiwan', latitude: 23.69781, longitude: 120.960515 },
  TZ: { name: 'Tanzania', latitude: -6.369028, longitude: 34.888822 },
  UA: { name: 'Ukraine', latitude: 48.379433, longitude: 31.16558 },
  UG: { name: 'Uganda', latitude: 1.373333, longitude: 32.290275 },
  UM: { name: 'U.S. Minor Outlying Islands', latitude: '', longitude: '' },
  US: { name: 'United States', latitude: 37.09024, longitude: -95.712891 },
  UY: { name: 'Uruguay', latitude: -32.522779, longitude: -55.765835 },
  UZ: { name: 'Uzbekistan', latitude: 41.377491, longitude: 64.585262 },
  VA: { name: 'Vatican City', latitude: 41.902916, longitude: 12.453389 },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    latitude: 12.984305,
    longitude: -61.287228,
  },
  VE: { name: 'Venezuela', latitude: 6.42375, longitude: -66.58973 },
  VG: {
    name: 'British Virgin Islands',
    latitude: 18.420695,
    longitude: -64.639968,
  },
  VI: {
    name: 'U.S. Virgin Islands',
    latitude: 18.335765,
    longitude: -64.896335,
  },
  VN: { name: 'Vietnam', latitude: 14.058324, longitude: 108.277199 },
  VU: { name: 'Vanuatu', latitude: -15.376706, longitude: 166.959158 },
  WF: {
    name: 'Wallis and Futuna',
    latitude: -13.768752,
    longitude: -177.156097,
  },
  WS: { name: 'Samoa', latitude: -13.759029, longitude: -172.104629 },
  XK: { name: 'Kosovo', latitude: 42.602636, longitude: 20.902977 },
  YE: { name: 'Yemen', latitude: 15.552727, longitude: 48.516388 },
  YT: { name: 'Mayotte', latitude: -12.8275, longitude: 45.166244 },
  ZA: { name: 'South Africa', latitude: -30.559482, longitude: 22.937506 },
  ZM: { name: 'Zambia', latitude: -13.133897, longitude: 27.849332 },
  ZW: { name: 'Zimbabwe', latitude: -19.015438, longitude: 29.154857 },
};
